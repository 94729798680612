import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "element-ui/lib/theme-chalk/index.css";
// import "./assets/styles/element-variables.scss";
import "./assets/styles/reset.scss";
import "./assets/styles/index.scss"; // global css
import VueClipboard from "vue-clipboard2";
import vueAwesomeCountdown from "vue-awesome-countdown";
import Axios from "axios";
import ElementUI from "element-ui";
import local from "element-ui/lib/locale";
import cn from "element-ui/lib/locale/lang/zh-CN";
import en from "element-ui/lib/locale/lang/en";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import i18n from "./i18n";

Vue.use(VueClipboard);
Vue.use(vueAwesomeCountdown, "vac");

const lang=window.sessionStorage.getItem("lg");

lang === "zh-CN" ? local.use(cn) : local.use(en);
Vue.prototype.$lang=lang;

Vue.config.productionTip=false;
Vue.prototype.$axios=Axios; //把 axios 请求加载到全局中去

Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: "data-source",
    zIndex: 6666,
  },
});

// 显示loading
// function showLoading(lang = "cn") {
// 	if (requestCount === 0) {
// 		if (lang == "cn") {
// 			loading = Message({
// 				message: "正在执行中...",
// 				duration: 0,
// 			});
// 		} else {
// 			loading = Message({
// 				message: "In progress...",
// 				duration: 0,
// 			});
// 		}
// 	}
// 	requestCount++;
// }

// 隐藏loading
// function hideLoading() {
// 	if (requestCount > 0) {
// 		requestCount--;
// 	}
// 	if (loading && requestCount === 0) {
// 		loading.close();
// 	}
// }

// 添加请求拦截器
Axios.interceptors.request.use((config) => {
  let urlArr=config.url.split("/");
  //后台头部验证
  if (urlArr[1] == "a1") {
    let arr=["/a1/admin/login", "/a1/admin/logout"]; //设置不需要加载header的地址
    if (!arr.includes(config.url)) {
      //如果不存在，则需要进行加入
      let token=window.sessionStorage.getItem("token"); //再获取到 token
      if (token) {
        config.headers["Authorization"]=JSON.parse(token);
      }
    }
    //显示loading
    //showLoading();
  }

  //前端PC界面
  if (urlArr[1] == "w1") {
    let arr=["/w1/user/login"]; //设置不需要加载header的地址
    if (!arr.includes(config.url)) {
      //如果不存在，则需要进行加入
      let token=window.sessionStorage.getItem("webToken"); //再获取到 token
      if (token) {
        config.headers["Authorization"]=JSON.parse(token);
      }
    }

    // if(i18n.locale == "en"){
    // 	showLoading("en");
    // } else {
    // 	showLoading("cn");
    // }
  }

  // 在发送请求之前做些什么
  return config;
});

// 添加响应拦截器
Axios.interceptors.response.use(
  (response) => {
    // 隐藏loading
    // hideLoading();
    // 对响应数据做点什么
    return response;
  },
  (err) => {
    //400 error
    let requestUrl=err.response.config.url;
    let urlArr=requestUrl.split("/");

    if (err.response.status === 401) {
      if (urlArr[1] === "w1") {
        window.location.href="/";
        return;
      }
      if (urlArr[1] === "a1") {
        window.location.href="#/adminLogin";
      }
    }
    // 隐藏loading
    // hideLoading();
    // 对响应错误做点什么
    return Promise.reject(err);
  },
);

Vue.use(ElementUI, {
  size: "small",
});

new Vue({
  router,
  store,

  data: function() {
    return {
      DownUrl: process.env.VUE_APP_DOW_URL || "https://mall.ximi-v.com", //国际下载地址
      //DownUrl: "https://order.xmvogue.com", //国内下载地址
      //DownUrl: "http://127.0.0.1:8090", //测试地址
    };
  },

  i18n,
  render: (h) => h(App),
}).$mount("#app");
